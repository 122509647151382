<!--
 * @Descripttion: 服务数据报表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-04 10:55:03
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-07 15:50:38
-->
<template>
    <div class="serviceDataReport">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">客服数据报表</div>
        </div>
        <div class="case-wrap select-wrap">
            <div class="top-select">
                <div class="select">
                    <el-select class="sele" clearable  @change="selectChangeBrand" v-model="epId" placeholder="请选择品牌筛选">
                        <el-option
                            v-for="item in brandOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-if="userInfo.kf_level == 1" clearable @change="selectChangeUser" v-model="userId" placeholder="请选择用户筛选">
                        <el-option
                            v-for="item in userOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!-- <el-tooltip class="item" effect="dark" content="提成" placement="top">
                    <i @click="isAllocation = true" class="iconfont el-icon-data-line"></i>
                </el-tooltip> -->
            </div>
            
            <div class="title">
                <div class="title-wrap">
                    <div class="tag"></div>
                    <div class="title">数据汇总</div>
                </div>
            </div>
            <div class="content-wrap" v-loading="loading1">
                <div class="item">
                    <span>{{basicData.enterprise_brand_count}}</span>品牌数
                </div>
                <div class="item">
                    <span>{{basicData.shop_count}}</span>门店数
                </div>
                <div class="item">
                    <span>{{basicData.user_count}}</span>账号数
                </div>
                <div class="item">
                    <span>{{basicData.shop_follow_count}}</span>跟进数
                </div>
                <div class="item">
                    <span>{{basicData.feedback_count}}</span>反馈数
                </div>
            </div>
        </div>
        <div class="case-wrap echart-wrap">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">服务数据</div>
            </div>
            <div class="echart" v-loading="loading2">
                <div id="storeAuthGrades" style="width: 25%;height: 350px;"></div>
                <div id="applyAuthGrades" style="width: 25%;height: 350px;"></div>
                <div id="feedbackGrades" style="width: 25%;height: 350px;"></div>
                <div id="activeShopGrades" style="width: 25%;height: 350px;"></div>
            </div>
        </div>
        <div class="case-wrap line-echart-wrap">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">跟进数据</div>
            </div>
            <div class="content">
                <div class="screen-wrap">
                    <commonSelect
                        @changeDate="changeDate"
                        @clickTagDate="clickTagDate"
                    ></commonSelect>
                </div>
                <div v-loading="loading3">
                    <div id="followGrades" style="width: 100%;height: 350px;"></div>
                </div>
            </div>
        </div>
        <el-dialog title="提成金额查询" :visible.sync="isAllocation" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div v-loading="btnLoading">
                <div class="money" v-if="moneyShow">
                    <div class="tag">提成金额为：<span>{{money}}</span>元</div>
                </div>
                <div class="content" v-else>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                        <el-form-item label="选择用户" prop="user_id">
                            <el-select  @change="changeUser" clearable v-model="ruleForm.user_id" placeholder="请选择用户">
                                <el-option
                                    v-for="item in userOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择品牌" prop="ep_id">
                            <el-select clearable v-model="ruleForm.ep_id" placeholder="请选择品牌">
                                <el-option
                                    v-for="item in epOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择时间" prop="date_type">
                            <el-select v-model="ruleForm.date_type" placeholder="请选择时间">
                                <el-option
                                    v-for="item in dateOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="输入密码" prop="password">
                            <el-input v-model="ruleForm.password" type="password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="btn-wrap" v-if="!moneyShow">
                    <el-button :loading="btnLoading" @click="submitForm" type="primary">查询</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
import {storeAuthEcharts,applyAuthEcharts,feedbackEcharts,activeShopEcharts} from "@/echarts/pieEchartData";
import {followLineEcharts} from "@/echarts/lineEchartData";
export default {
    components: {
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'serviceDataReport',
            userInfo: {},
            brandOptions: [],
            userOptions: [],
            epId: '',
            userId: '',
            basicData: {},
            dateType: '',
            startDate: '',
            endDate: '',
            isAllocation: false,
            btnLoading: false,
            epOptions: [],
            dateOptions: [{
                value: 'month',
                label: '本月'
            },{
                value: 'last month',
                label: '上月'
            }],
            ruleForm: {
                user_id: '',
                ep_id: '',
                date_type: 'month',
                password: ''
            },
            rules: {
                user_id: [
                    { required: true, message: '请选择用户', trigger: 'change' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
            },
            money: '',
            moneyShow: false,
            loading1: false,
            loading2: false,
            loading3: false,
        }
    },
    mounted () {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.getEpConditions();
        this.getUserConditions();
        this.getDataReport();
        this.getDataReportPieChart();
        this.getDataReportLineChart();
    },
    methods: {
        // 查询
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                this.btnLoading = true;
                common.connect("/customerservicev1/data_report/performance",this.ruleForm,(res)=>{
                    this.money = res.data.money;
                    this.moneyShow = true;
                    this.btnLoading = false;
                });
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        // 关闭弹窗
        handleClose(){
            this.isAllocation = false;
            this.moneyShow = false;
            this.ruleForm.user_id = '';
            this.ruleForm.ep_id = '';
            this.ruleForm.date_type = 'month';
            this.ruleForm.password = '';
        },
        // 用户筛选
        selectChangeUser(){
            this.getDataReport();
            this.getDataReportPieChart();
            this.getDataReportLineChart();
        },
        // 品牌筛选
        selectChangeBrand(){
            this.getDataReport();
            this.getDataReportPieChart();
            this.getDataReportLineChart();
        },
        // 获取饼图数据
        getDataReportPieChart(){
            let params = {
                ep_id: this.epId,
                user_id: this.userId
            }
            this.loading2 = true;
            common.connect("/customerservicev1/data_report/customerServiceDataReportPieChart",params,(res)=>{
                storeAuthEcharts('storeAuthGrades',res.data.store_auth);
                applyAuthEcharts('applyAuthGrades',res.data.apply_auth);
                feedbackEcharts('feedbackGrades',res.data.feedback);
                activeShopEcharts('activeShopGrades',res.data.active_shop);
                this.loading2 = false;
            });
        },
        // 获取基本数据
        getDataReport(){
            let params = {
                ep_id: this.epId,
                user_id: this.userId
            }
            this.loading1 = true;
            common.connect("/customerservicev1/data_report/customerServiceDataReport",params,(res)=>{
                this.basicData = res.data;
                this.loading1 = false;
            });
        },
        // 获取跟进数据
        getDataReportLineChart(){
            let params = {
                ep_id: this.epId,
                user_id: this.userId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading3 = true;
            common.connect("/customerservicev1/data_report/customerServiceDataReportLineChart",params,(res)=>{
                followLineEcharts('followGrades',res.data);
                this.loading3 = false;
            });
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.currentPage = 1;
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getDataReportLineChart();
        },
        clickTagDate(item){
            this.dateType = item.label;
            this.currentPage = 1;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getDataReportLineChart();
            }
        },
        // 获取用户数据
        getUserConditions(){
            common.connect("/customerservicev1/admin/userConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.user_id,
                    label: v.nickname
                }
                })
                this.userOptions = resultSecond;
            });
        },
        // 获取用户品牌数据
        changeUser(){
            this.ruleForm.ep_id = '';
            let params = {
                user_id: this.ruleForm.user_id
            }
            common.connect("/customerservicev1/enterprise_brand/EpConditions",params,(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.ep_id,
                    label: v.name
                }
                })
                this.epOptions = resultSecond;
            });
        },
        // 获取品牌数据
        getEpConditions(){
            common.connect("/customerservicev1/enterprise_brand/EpConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.ep_id,
                    label: v.name
                }
                })
                this.brandOptions = resultSecond;
            });
        },
    },
}
</script>

<style lang='scss'>
.serviceDataReport{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 5px 0;
            .tag{
                width: 3px;
                height: 15px;
                background: #409EFF;
                border-radius: 10px;
            }
            .title{
                font-size: 16px;
                font-weight: bold;
                padding-left: 10px;
            }
        }
    }
    .select-wrap{
        .top-select{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .select{
                .sele{
                    margin-right: 20px;
                }
            }
            .iconfont{
                font-size: 26px;
                cursor: pointer;
            }
        }
        
        .title{
            margin: 10px 0;
        }
    }
    .content-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item{
            border: 1px solid #ddd;
            border-radius: 6px;
            width: 18%;
            display: flex;
            justify-content: center;
            align-items: baseline;
            height: 120px;
            line-height: 120px;
            span{
                font-size: 26px;
                font-weight: bold;
                color: #000000;
                margin-right: 10px;
            }
        }
    }
    .echart-wrap{
        .echart{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
    }
    .line-echart-wrap{
        .content{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }
    }

    .dialog-wrap{
        width: 600px;
        .money{
            text-align: center;
            padding: 20px 0;
            .tag{
                font-size: 16px;
                margin-left: 20px;
                span{
                    color: #000000;
                    font-size: 20px;
                    padding: 0 5px;
                }
            }
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 20px;
            padding: 30px 0;
            .el-input{
                width: 470px;
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            margin-top: -20px;
            padding-bottom: 15px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
